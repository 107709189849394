import React, { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Homepage from './Pages/Homepage'
import ContactUs from './Pages/ContactUs'
import Privacy from './Pages/Privacy'
import ProductPage from './Pages/ProductPage'
import TermsAndConditions from './Pages/TermsAndConditions'
import Checkout from './Pages/Checkout'
import Confirmation from './Pages/Confirmation'
import Header from './PageSections/Header'
import Footer from './Pages/Footer'
import CookiesDisplay from './Components/CookiesDisplay'
import usePixel from './Hooks/usePixel'
import AboutUs from './Pages/AboutUs'

function Routes() {
  const location = useLocation()
  const { viewPage } = usePixel()
  useEffect(() => {
    viewPage()
    if (!location.pathname.startsWith('/product')) {
      document.title = 'Gartners'
    }
  }, [location, viewPage])
  return (
    <Switch>
      <Route path="/klarna/confirmation" />
      {/* Paths that contain iFrames cant have a fixed header, it bugs the iFrame */}
      <Route path={['/checkout', '/confirmation']}>
        <Header />
        <Switch>
          <Route path="/checkout" component={Checkout} />
          <Route path="/confirmation" component={Confirmation} />
        </Switch>
        <Footer />
        <CookiesDisplay />
      </Route>
      <Route>
        <Header lockHeader />
        <Switch>
          <Route path="/privacy" component={Privacy} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/product/:type/:id" component={ProductPage} />
          <Route path="/" component={Homepage} />
        </Switch>
        <Footer />
        <CookiesDisplay />
      </Route>
    </Switch>

  )
}

export default Routes
