import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import maxDevice from '../Style/deviceSize'
import useContent from '../Hooks/useContent'
import useSkeleton from '../Hooks/useSkeleton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  justify-content: center;
  width: 100%;
`

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  margin-top: 25px;
  align-items: center;
  flex-direction: column;
`

const PictureContainer = styled.div`
  width: 50%;
  height: 80px;
  max-width: 300px;
  display: flex;
  justify-content: center;

  @media ${maxDevice.tablet} {
    width: 100%;
  }
;
`
const LinkText = styled(Link)`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
`

const StyledLink = styled(Link)`
  width: 100%;
  height:100%;
`

const CopyRightSpace = styled.h5`
  width: 100%;
  text-align: center;
  color: lightgray;
  margin-top: -30px;
`
const LineDiv = styled.div`
  width: 100%;
  margin-top: 50px;
  border-bottom: 1px solid lightgray;
`
const Picture = styled.div`
  background-image: url("/VO6pd7v.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%; 
`

function Footer() {
  const { L, TRANSLATION_KEYS: { FOOTER } } = useContent()
  const { ContentSkeleton } = useSkeleton()
  return (
    <Container>
      <LinkContainer>
        <LinkText to="/privacy">
          <ContentSkeleton skeletons={[{ width: 250 }]}>
            {L(FOOTER.PRIVACY)}
          </ContentSkeleton>
        </LinkText>
        <LinkText to="/terms-and-conditions">
          <ContentSkeleton skeletons={[{ width: 250 }]}>
            {L(FOOTER.TERMS)}
          </ContentSkeleton>
        </LinkText>
        <LinkText to="/contact-us">
          <ContentSkeleton skeletons={[{ width: 250 }]}>
            {L(FOOTER.CONTACT_US)}
          </ContentSkeleton>
        </LinkText>
        <LinkText to="/about-us">
          <ContentSkeleton skeletons={[{ width: 250 }]}>
            {L(FOOTER.ABOUT_US)}
          </ContentSkeleton>
        </LinkText>
      </LinkContainer>
      <LineDiv />
      <LinkContainer>
        <PictureContainer>
          <StyledLink to="/"><Picture /></StyledLink>
        </PictureContainer>
        <CopyRightSpace>{`© ${new Date().getFullYear()}`}</CopyRightSpace>
      </LinkContainer>
    </Container>
  )
}

export default Footer
