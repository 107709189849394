import React from 'react'
import styled from 'styled-components/macro'
import GoBackButton from '../Components/GoBackButton'
import maxDevice from '../Style/deviceSize'
import useContent from '../Hooks/useContent'
import Markdown from '../Components/Markdown'

const TextContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 75px;

  @media ${maxDevice.tablet}{
    margin-left: 0px;
    text-align:center;
    width:100%;
    padding:25px;
  }
`

const BigContainer = styled.div`
  padding: 75px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;

  @media ${maxDevice.tablet}{
    padding: 0px;
  } 
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${maxDevice.tablet}{
    display:none;
  }
`

const Heading = styled.h1`
  margin-top: 0px;
`
const Textspace = styled.h3`
  margin-top: 10px;
`
const Text = styled.p`
  margin-top: 0px;
`

const Image = styled.img`
width:350px;
height:370px;
margin-right:12px;

@media ${maxDevice.tablet}{
  max-width:300px;
  max-height:320px;
  margin:20px 0px;
  }

`

const ImageContainer = styled.div`
display:flex;
justify-content:space-around;
width:85%;
margin-top:24px;
flex-direction:row;
align-items:center;
margin-left:75px;

@media ${maxDevice.tablet}{
flex-direction:column;
width:100%;
margin-left: 0px;
}
`

function AboutUs() {
  const { L, TRANSLATION_KEYS: { ABOUT_US } } = useContent()
  return (
    <BigContainer>
      <LeftContainer>
        <GoBackButton size="small" />
      </LeftContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <TextContainer>
          <Markdown
            Heading1={Heading}
            Heading3={Textspace}
            Paragraph={Text}
          >
            {L(ABOUT_US.MARKDOWN)}
          </Markdown>
        </TextContainer>
        <ImageContainer>
          <Image src="https://i.imgur.com/mwkEWfT.jpg" />
          <Image src="https://i.imgur.com/99Chuao.jpg" />
        </ImageContainer>
      </div>
    </BigContainer>
  )
}

export default AboutUs
